import React from 'react'

import Layout from 'components/Layout'
import Contact from 'components/Contact'
import Seo from 'components/Shared/SEO'
import { useText } from 'texts'

const FORM_SUBMISSION_ID = 'submissionGuid'

const ContactPage = ({ path, location }) => {
  const { t } = useText()

  const search = location?.search || ''
  const submitted = search.includes(`${FORM_SUBMISSION_ID}=`)

  return (
    <Layout>
      <Seo
        path={path}
        title={t('contact.seo.title')}
        description={t('contact.seo.description')}
      />
      <Contact submitted={submitted} />
    </Layout>
  )
}

export default ContactPage
