import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Screens from 'theme/screens'
import { useText } from 'texts'

const StyledContact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 50%;
  padding-right: 40px;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    min-width: auto;
    padding-right: 56px;
  }

  @media (max-width: ${Screens.sm}) {
    position: relative;
    max-width: 100%;
    padding-right: 0;
    align-items: center;
    text-align: center;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  width: 50%;
  margin-top: -128px;
  min-height: ${({ submitted }) =>
    submitted ? 'calc(100vh - 208px)' : '864px'};

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    min-height: auto;
    overflow: hidden;
    margin-top: 0;
  }
`

const Contact = ({ submitted }) => {
  const { t } = useText()

  return (
    <StyledContact>
      <Container className="container full">
        <TextContainer>
          <h2 className="text-primary-100">{t('contact.release.title')}</h2>
          <div
            className="mb-24"
            dangerouslySetInnerHTML={{
              __html: t('contact.release.description')
            }}
          />
        </TextContainer>
      </Container>
      <ImageContainer submitted={submitted}>
        <StaticImage
          src="hero.png"
          alt={t('contact.hero.alt')}
          placeholder="blurred"
          layout="constrained"
        />
      </ImageContainer>
    </StyledContact>
  )
}

export default Contact
